import { memo } from 'react';
import classes from './styles.module.scss';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import { appRoutes } from 'routers/appRoutes';
import UserDropdown from './components/UserDropdown';
import { DefaultLogo } from 'assets';
import { Box } from '@mui/material';
import useAutoLogout from 'hooks/useCheckInactiveTimeout';
import AuthService from 'services/authService';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { setLogoutUserReducer } from 'redux/reducers/User/actionTypes';

interface HeaderProps {}

const Header = memo((props: HeaderProps) => {

  const dispatch = useDispatch();

  const logout = async () => {
    
    AuthService.logout();

    dispatch(setLogoutUserReducer());

    dispatch(push(appRoutes.public.login));
  }

  useAutoLogout({ callback: logout, timeout: Number(process.env.REACT_APP_INACTIVE_TIMEOUT_SECONDS) * 1000 });

  return (
    <AppBar className={classes.headerWrapper} position="static" color="inherit">
      <Toolbar className={classes.toolBar}>
        <Box onClick={() => {window.location.href = process.env.REACT_APP_HOME_PAGE_URL;}} className={classes.logo}>
          <img src={DefaultLogo} alt="Logo" />
        </Box>
        <Link 
          to={appRoutes.private.home} 
          onClick={(e) => {
            e.preventDefault();
            const baseUrl = window.location.origin + appRoutes.private.home;
            window.location.href = baseUrl; 
          }}><p  className={classes.appName}>Interactive Demographics</p>
        </Link>
        <UserDropdown />
      </Toolbar>
    </AppBar>
  );
});

export default Header;

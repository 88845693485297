import { memo, useEffect, useMemo, useState } from 'react';
import classes from './styles.module.scss';
import { Card, Grid, CardContent, Tooltip } from '@mui/material';
import { DataGrid, GridColDef, GridFilterModel, GridPaginationModel, GridSortModel, GridToolbar } from '@mui/x-data-grid';
import { IDataRow, IEditUser } from 'interfaces/demographics';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import ModalEditVariable from './ModalEditVariable';
import { IDataPagination, ISortItem } from 'interfaces/common';
import ToastService from 'services/toastService';
import ApiService from 'services/apiService';
import apiRoutes from 'configs/apiRoutes';
import Messages from 'configs/messages';
import { useDispatch } from 'react-redux';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import Chip from '@mui/material/Chip';
import { Box } from '@mui/material';
import CryptoJS from 'crypto-js';
import { IConfirmModal } from 'interfaces/common';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { EAccountStatus, EUserType } from 'configs/enums';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import { BlockIcon, RestoreIcon } from 'assets';
import { PagingParams, _paramsPagination } from 'configs/general';
import useDebounce from 'hooks/useDebounce';
import categoryPricingPlans from 'configs/plansNew';
interface CellType {
  row: IDataRow;
}
export interface GetUsersParams {
  page: number,
  limit: number,
  solutionIds?: number[],
  keyword?: string,
  sortedField?: string;
  isDescending?: boolean;
}

interface AdminEditUserPageProps { }

const AdminEditUserPage: React.FC<AdminEditUserPageProps> = memo((props: AdminEditUserPageProps) => {
  const dispatch = useDispatch();

  const columns: GridColDef[] = [
    {
      flex: 0.07,
      field: 'id',
      headerName: '#',
    },
    {
      flex: 0.2,
      minWidth: 150,
      field: 'firstName',
      headerName: 'Name',
    },
    {
      flex: 0.2,
      minWidth: 150,
      field: 'email',
      headerName: 'Email',
    },
    {
      flex: 0.2,
      minWidth: 150,
      field: 'phone',
      headerName: 'Phone',
    },
    {
      flex: 0.2,
      minWidth: 150,
      field: 'type',
      headerName: 'Type',
    },
    {
      flex: 0.2,
      minWidth: 100,
      field: 'totalloginInDay',
      headerName: 'Logins/Day',
    },
    {
      flex: 0.2,
      minWidth: 150,
      field: 'totalloginInMonth',
      headerName: 'Logins/Month',
    },
    {
      flex: 0.2,
      minWidth: 150,
      field: 'package',
      headerName: 'Package',
      renderCell: ({ row }: CellType) => {
        return (
          <>
            {row.type === EUserType.Custom && row?.subscription ? (
              <>
                {categoryPricingPlans.find(category => category.id === row?.subscription?.categoryId)?.title || null}
              </>
            ) : (
              <>
                {row.type === EUserType.Customer && row?.subscription ? (
                  <>{categoryPricingPlans.flatMap(category => category.listPlan).find(plan => plan.id === row?.subscription?.planId)?.title || null}</>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      flex: 0.2,
      minWidth: 120,
      field: 'createdBy',
      headerName: 'CreatedBy',
      sortable: false,
      renderCell: ({ row }: CellType) => {
        return (
          <>
            {row.type === EUserType.Custom && ("ADMIN")}
          </>
        );
      },
    },
    {
      flex: 0.2,
      minWidth: 150,
      field: 'status',
      headerName: 'Status',
      renderCell: ({ row }: CellType) => {
        return (
          <Chip label={`${row.status ? 'Activate' : 'Deactivate'}`} color={`${row.status ? 'success' : 'error'}`} />
        );
      },
    },
    {
      flex: 0.3,
      minWidth: 120,
      field: '',
      sortable: false,
      headerName: 'Action',
      renderCell: ({ row }: CellType) => {
        return (
          <div className={classes.actionButton}>
            <PrimaryButton
              disabled={row?.type === EUserType.Admin}
              onClick={() => {
                handleOpenDialog()
                setModalEditVariable(row)
              }}
            >Edit</PrimaryButton>
            {row?.status === EAccountStatus.Active ? (
              <Tooltip title="Block">
                <PrimaryButton
                  style={{marginLeft: '10px'}}
                  disabled={[EUserType.Admin, EUserType.Demo].includes(row?.type  as EUserType)}
                  onClick={() => {
                    setConfirmModal({
                      isOpen: true,
                      title: 'Block user',
                      description: 'Do you want delete user!',
                      onSubmit: () => onChangeStatus(row.id),
                    })
                  }}
                >
                  <div className={classes.iconButton}>
                    <BlockIcon/>
                  </div>
                </PrimaryButton>
              </Tooltip>
              
            ) : (
              <Tooltip title="Unblock">
                <SecondaryButton 
                  style={{marginLeft: '10px'}}
                  onClick={() => {
                    setConfirmModal({
                      isOpen: true,
                      title: 'Unblock user',
                      description: 'Do you want unblock user!',
                      onSubmit: () => onChangeStatus(row.id),
                    })
                  }}
                >
                  <div className={classes.iconButtonBlack}>
                    <RestoreIcon/>
                  </div>
                </SecondaryButton>
              </Tooltip>
              
            )}
            
          </div>
        );
      },
    },
  ];
  
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalEditVariable, setModalEditVariable] = useState<IDataRow>(null);
  const [keyword, setKeyword] = useState<string>(null);
  const [sort, setSort] = useState<ISortItem>(null);
  const [datasetHistories, setDatasetHistories] = useState<IDataPagination<any>>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [categorySelected, setCategorySelected] = useState<number>(1);
  const [confirmModal, setConfirmModal] = useState<IConfirmModal>(null)
  const [pagingParams, setPagingParams] = useState<PagingParams>(_paramsPagination);
  

  const handleOpenDialog = () => {
    setIsOpen(true)
  }

  const onCloseConfirmModal = () => {
    setConfirmModal(null);
  };

  const onCloseModals = () => {
    setConfirmModal(null);
  };

  const fetchData = useDebounce(() => {
      setLoading(true);
      ApiService.GET(apiRoutes.user.findAll, paramsFilter)
        .then((res) => {
          setDatasetHistories({
            data: res?.data,
            meta: res?.meta,
          });
        })
        .catch(() => ToastService.error(Messages.error.default))
        .finally(() => setLoading(false));
    },500
  );


  const onPaginationChange = (val: GridPaginationModel) => {
    setPagingParams({
      ...pagingParams,
      page: val.page + 1,
      limit: val.pageSize,
    })
  }

  const paramsFilter = useMemo(()=>{
    const _params: GetUsersParams = {
      limit: pagingParams?.limit || 10,
      page: pagingParams?.page || 1,
      keyword: keyword,
      sortedField: sort?.sortedField,
      isDescending: sort?.isDescending,
    }
    if (keyword !== undefined) {
      _params.keyword = keyword || undefined
    }
    return _params;
  },[pagingParams, sort, keyword]) 

  const onSortChange = (val: GridSortModel) => {
    const sortQuery: ISortItem =
      val?.length > 0 ? { sortedField: val?.[0]?.field ?? 'null', isDescending: val?.[0]?.sort === 'asc' ? false : true } : null;
    setSort(sortQuery);
  }

  const onFilterChange = (val: GridFilterModel) => {
    setKeyword(val?.quickFilterValues?.[0] ?? '');
  }

  const onCloseModal = () => {
    setIsOpen(false);
    setModalEditVariable(null)
  };

  const onSubmit = (data: IEditUser) => {
    dispatch(setIsLoadingReducer(true));
    let type = 'POST';
    let apiName = 'createUser'
    const params = {
      firstName: data?.firstName ?? undefined,
      lastName: data?.lastName ?? undefined,
      email: data?.email ?? undefined,
      password: data?.password ? CryptoJS.AES.encrypt(data?.password, process.env.REACT_APP_AES_KEY).toString() : '',
      phone: data?.phone ?? undefined,
      categoryId: categorySelected,
      startDate: data?.startDate ?? undefined,
      endDate: data?.endDate ?? undefined,
    }
    if(data?.id){
      type = 'PUT';
      apiName = 'updateUser'
      params['id'] = data.id;
    }
    ApiService[type](apiRoutes.user[apiName], params)
      .then((response) => {
        onCloseModal();
        setDatasetHistories({
          ...datasetHistories,
          data: datasetHistories?.data?.map((item) => {
            if (item?.id === response?.data?.id) {
              return response?.data;
            }
            return item;
          }),
        });
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onChangeStatus = (id: number) => {
    setLoading(true)
    ApiService.PUT(`${apiRoutes.user.changeStatus}/${id}`)
      .then(() => {
        setLoading(false)
        fetchData();
        onCloseConfirmModal();
        ToastService.success(Messages.success.deleted);
      })
      .catch(() => {
        setLoading(false)
        ToastService.error(Messages.error.default);
      });
  }

  useEffect(()=>{
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[paramsFilter])

  // const onDeleteUser = useCallback((id: number) => {
  //   setLoading(true)
  //   ApiService.DELETE(`${apiRoutes.user.deleteUser}/${id}`)
  //     .then(() => {
  //       setLoading(false)
  //       fetchData();
  //       onCloseConfirmModal();
  //       ToastService.success(Messages.success.deleted);
  //     })
  //     .catch(() => {
  //       setLoading(false)
  //       ToastService.error(Messages.error.default);
  //     });
  // }, []);

  return (
    <div className={classes.container}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ borderRadius: '10px' }}>
            <Grid
              container
              direction="row"
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className={classes.title}>Users</p>
              <Box sx={{ padding: '20px 25px 0px' }}>
                <PrimaryButton className="primary" onClick={handleOpenDialog}>Create</PrimaryButton>
              </Box>
            </Grid>

            <CardContent className={classes.content}>
              <DataGrid<any>
                autoHeight
                pagination
                columns={columns}
                rows={datasetHistories?.data ?? []}
                rowCount={datasetHistories?.meta?.itemCount || 0}
                disableRowSelectionOnClick
                disableColumnSelector
                paginationMode="server"
                filterMode="server"
                sortingMode="server"
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                disableColumnFilter
                onPaginationModelChange={onPaginationChange}
                onFilterModelChange={onFilterChange}
                onSortModelChange={onSortChange}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[5, 10, 25, 50]}
                loading={loading}
                sx={{
                  '*': {
                    outline: 'none !important',
                  },
                  '.MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 600,
                  },
                  '.MuiCircularProgress-svg': {
                    color: 'var(--primary)',
                  },
                  '.MuiButtonBase-root': {
                    color: 'var(--black)',
                  },
                  '.MuiInputBase-root::after': {
                    borderColor: 'var(--black)',
                  },
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {isOpen ?
        <ModalEditVariable
          isOpen={isOpen}
          data={modalEditVariable}
          onClose={onCloseModal}
          onSubmit={onSubmit}
          setCategorySelected={setCategorySelected}
          categorySelected={categorySelected}
        />
        :
        null
      }
      <ConfirmModal
        isOpen={confirmModal?.isOpen}
        title={confirmModal?.title}
        description={confirmModal?.description}
        onSubmit={confirmModal?.onSubmit}
        onClose={onCloseModals}
      />
    </div>
  );
});

export default AdminEditUserPage;


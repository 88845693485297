import classes from './styles.module.scss';
import { memo, useMemo } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/Input';
import dayjs from 'dayjs';

interface SetNameFormData{
  name: string
}

interface ModalSetNameSaveScenarioProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: string) => void;
}

const ModalSetNameSaveScenario = memo((props: ModalSetNameSaveScenarioProps) => {
  const { isOpen, onClose, onSubmit } = props;

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      name: Yup.string()
        .required('Name is required.')
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SetNameFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues:{
      name: `Interactive Demographics Scenario - ${dayjs().format('YYYY-MM-DD')}`
    }
  });

  const _onSubmit = (data: SetNameFormData) => {
    onSubmit(data.name);
  }

  return (
    <Dialog transitionDuration={0.3} open={isOpen} onClose={onClose} className={classes.container}>
      <DialogTitle className={classes.title}>Save scenario</DialogTitle>

      <DialogContent>
        <form id="form-download-table" onSubmit={handleSubmit(_onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Input
                label="Name"
                placeholder="Name file save"
                inputRef={register('name')}
                errorMessage={errors.name?.message}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions sx={{ mt: 2 }} className={classes.dialogAction}>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>

        <PrimaryButton type="submit" form="form-download-table">
          Save
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
});

export default ModalSetNameSaveScenario;

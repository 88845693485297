export const appRoutes = {
  public: {
    login: '/login',
    register: '/register',
  },
  private: {
    home: '/',
    account: '/account',
    subscription: '/subscription',
    pricing: '/pricing',
    checkoutSuccess: '/checkout-success',
    checkoutFailed: '/checkout-failed',
    acceptCondition: '/condition',
    report: '/report'
  },
  fullyAllowed: {
    checkEmail: '/check-email',
    forgotPassword: '/forgot-password',
    verifyEmail: '/verify/email/:verifyCode',
    resetPassword: '/callback/forgot-password/:resetPasswordCode',
    checkForgotPasswordEmail: '/check-reset-password-email',
  },
  admin: {
    root: '/admin',
    importDataset: '/admin/import-dataset',
    editTable: '/admin/edit-table',
    users: '/admin/users'
  },
};

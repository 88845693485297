import { Box, Card, CardContent, Grid, Stack } from '@mui/material';
import { Fragment, memo, useCallback, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { push } from 'connected-react-router';
import { appRoutes } from 'routers/appRoutes';
import { DataGrid, GridColDef, GridFilterModel, GridPaginationModel, GridSortModel, GridToolbar } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer } from 'redux/reducers';
import pricingPlans from 'configs/plans';
import ApiService from 'services/apiService';
import apiRoutes from 'configs/apiRoutes';
import { setUserReducer } from 'redux/reducers/User/actionTypes';
import ToastService from 'services/toastService';
import Messages from 'configs/messages';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import { Link } from 'react-router-dom';
import { ENodeEnv, ESubscriptionStatus, EUserType } from 'configs/enums';
import { IConfirmModal, IDataPagination, ISortItem } from 'interfaces/common';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { IBilling } from 'interfaces/billing';
import StripeService from 'services/stripeService';

const columns: GridColDef[] = [
  {
    flex: 0.1,
    field: 'id',
    headerName: '#',
    renderCell: ({ row }: CellType) => {
      return `#-${row?.id}`;
    },
  },
  {
    flex: 0.3,
    minWidth: 150,
    field: 'planName',
    headerName: 'Plan',
  },
  {
    flex: 0.2,
    minWidth: 80,
    field: 'planPrice',
    headerName: 'Price',
  },
  {
    flex: 0.15,
    minWidth: 130,
    field: 'startDate',
    headerName: 'Start date',
    renderCell: ({ row }: CellType) => {
      return <p>{dayjs(row?.startDate)?.format('DD-MM-YYYY')}</p>;
    },
  },
  {
    flex: 0.2,
    minWidth: 150,
    field: 'endDate',
    headerName: 'End date',
    renderCell: ({ row }: CellType) => {
      return <p>{dayjs(row?.endDate)?.format('DD-MM-YYYY')}</p>;
    },
  },
  {
    flex: 0.2,
    minWidth: 150,
    field: 'status',
    headerName: 'Status',
  },
];

interface CellType {
  row: IBilling;
}

interface SubscriptionPageProps {}

const SubscriptionPage = memo((props: SubscriptionPageProps) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state: IReducer) => state?.user);

  const [confirmModal, setConfirmModal] = useState<IConfirmModal>(null);
  const [keyword, setKeyword] = useState<string>(null);
  const [sort, setSort] = useState<ISortItem>(null);
  const [datasetHistories, setDatasetHistories] = useState<IDataPagination<IBilling>>(null);
  const [loadingDatasetHistory, setLoadingDatasetHistory] = useState<boolean>(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (user?.type !== EUserType.Customer) {
      dispatch(push(appRoutes.private.home));
    }
  }, [user]);

  const fetchData = useCallback(
    (value?: { limit?: number; page?: number; keyword?: string; sort?: ISortItem }) => {
      const params: any = {
        limit: value?.limit || datasetHistories?.meta?.limit || 10,
        page: value?.page || datasetHistories?.meta?.page || 1,
        keyword: keyword,
        sortedField: sort?.sortedField,
        isDescending: sort?.isDescending,
      };
      if (value?.sort !== undefined) {
        params.sortedField = value?.sort?.sortedField;
        params.isDescending = value?.sort?.isDescending;
      }
      if (value?.keyword !== undefined) {
        params.keyword = value?.keyword || undefined;
      }

      setLoadingDatasetHistory(true);
      ApiService.GET(apiRoutes.billing.default, { ...params })
        .then((res) => {
          setDatasetHistories({
            data: res?.data,
            meta: res?.meta,
          });
        })
        .catch(() => ToastService.error(Messages.error.default))
        .finally(() => setLoadingDatasetHistory(false));
    },
    [datasetHistories, keyword, sort]
  );

  const onPaginationChange = useCallback(
    (val: GridPaginationModel) => {
      fetchData({
        page: val.page + 1,
        limit: val.pageSize,
      });
    },
    [fetchData]
  );

  const onSortChange = useCallback(
    (val: GridSortModel) => {
      const sortQuery: ISortItem =
        val?.length > 0 ? { sortedField: val?.[0]?.field ?? 'null', isDescending: val?.[0]?.sort === 'asc' ? false : true } : null;
      setSort(sortQuery);
      fetchData({
        sort: sortQuery,
      });
    },
    [fetchData]
  );

  const onFilterChange = useCallback(
    (val: GridFilterModel) => {
      setKeyword(val?.quickFilterValues?.[0] ?? '');
      fetchData({
        keyword: val?.quickFilterValues?.[0],
      });
    },
    [fetchData]
  );

  const onPauseOrResumeSubscription = () => {
    dispatch(setIsLoadingReducer(true));
    ApiService.POST(user?.subscription.isRecurring ? apiRoutes.subscription.pause : apiRoutes.subscription.resume)
      .then((response) => {
        onCloseModals();
        ToastService.success(Messages.success.default);
        dispatch(setUserReducer(response?.data));
      })
      .catch((error) => {
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onCancelSubscriptionSchedule = () => {
    dispatch(setIsLoadingReducer(true));
    ApiService.POST(apiRoutes.subscription.cancelSchedule)
      .then((response) => {
        onCloseModals();
        ToastService.success(Messages.success.default);
        dispatch(setUserReducer(response?.data));
      })
      .catch((error) => {
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onCloseModals = () => {
    setConfirmModal(null);
  };

  return (
    <div className={classes.subscriptionWrapper}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ borderRadius: '10px' }}>
            {user?.subscription ? (
              <Fragment>
                <p className={classes.cardHeader}>Subscription</p>
                <CardContent>
                  <Grid container rowSpacing={5} columnGap={5} justifyContent={'space-between'}>
                    <Grid item xs={12} sm={6}>
                      <Box sx={{ mb: 4 }}>
                        <p className={classes.title}>
                          Current plan: <strong>{StripeService.getPlanDetail(user?.subscription?.planId)?.title}</strong>.
                        </p>
                        <p className={classes.description}>
                        <strong>Description:</strong> {StripeService.getCategoryPlan(user?.subscription?.planId)?.subtitle}{StripeService.getCategoryPlan(user?.subscription?.planId)?.description && `, ${StripeService.getCategoryPlan(user?.subscription?.planId)?.description}`}
                        </p>
                        <p className={classes.description}>{StripeService.getPlanDetail(user?.subscription?.planId)?.description}.</p>
                      </Box>

                      <Box>
                        <p className={classes.title}>
                          Current period end: <strong>{dayjs(user?.subscription?.endDate).format('DD-MM-YYYY')}</strong>.
                        </p>
                        <p className={classes.description}>
                          Auto-renewal is {user?.subscription?.isRecurring ? 'active' : 'paused'}.{' '}
                          {!user?.subscription?.isRecurring ? 'Your subscription will be cancelled at the end of the current period.' : null}
                        </p>
                      </Box>

                      {user?.subscription?.scheduledPlanId ? (
                        <Box sx={{ mt: 4 }}>
                          <p className={classes.title}>
                            Subscription schedule: <strong>{pricingPlans.find((item) => item.id === user?.subscription?.scheduledPlanId)?.title}</strong>.
                          </p>
                          <p className={classes.description}>
                            Your subscription be switched to {pricingPlans.find((item) => item.id === user?.subscription?.scheduledPlanId)?.title} at the
                            end of the current period.
                          </p>
                        </Box>
                      ) : null}
                    </Grid>

                    <Grid item xs={12} sm={5} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                      <Stack className={classes.buttonContainer} gap={1} width={'100%'}>
                        <PrimaryButton
                          onClick={() => {
                            dispatch(push(appRoutes.private.pricing));
                          }}
                        >
                          Change plan
                        </PrimaryButton>

                        <PrimaryButton
                          onClick={() => {
                            if (user?.subscription?.isRecurring) {
                              setConfirmModal({
                                isOpen: true,
                                title: 'Pause / cancel subscription',
                                description:
                                  'Are you sure you want to pause or cancel your subscription? If there is any subscription schedule, it will also be canceled.',
                                onSubmit: onPauseOrResumeSubscription,
                              });
                            } else {
                              onPauseOrResumeSubscription();
                            }
                          }}
                        >
                          {user?.subscription?.isRecurring ? 'Pause / cancel' : 'Resume'} subscription
                        </PrimaryButton>

                        {user?.subscription?.scheduledPlanId ? (
                          <PrimaryButton
                            onClick={() => {
                              setConfirmModal({
                                isOpen: true,
                                title: 'Cancel subscription schedule',
                                description: 'Are you sure you want to cancel your subscription schedule?',
                                onSubmit: onCancelSubscriptionSchedule,
                              });
                            }}
                          >
                            Cancel subscription schedule
                          </PrimaryButton>
                        ) : null}

                        {user?.subscription ? (
                          <a
                            href={
                              process.env.REACT_APP_ENVIRONMENT === ENodeEnv.Development
                                ? 'https://billing.stripe.com/p/login/test_aEU6oT6KbfNF7EkaEE'
                                : 'https://billing.stripe.com/p/login/14k9BgbSN7pVarebII'
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <PrimaryButton className="w-100">Manage payment methods</PrimaryButton>
                          </a>
                        ) : null}
                      </Stack>
                    </Grid>
                  </Grid>

                  {user?.subscription?.status === ESubscriptionStatus.Unpaid ? (
                    <Box sx={{ mt: 4 }}>
                      <p className={classes.error}>
                        <u>Warning:</u> We encountered an issue while processing the payment for your subscription, please update your payment methods to
                        continue subscription.
                      </p>
                    </Box>
                  ) : null}
                </CardContent>
              </Fragment>
            ) : (
              <p className={classes.cardHeader}>
                You do not have any subscription, please subscribe a plan <Link to={appRoutes.private.pricing}>here</Link>.
              </p>
            )}
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ borderRadius: '10px' }}>
            <p className={classes.cardHeader}>Subscriptions</p>
            <CardContent>
              <DataGrid<IBilling>
                autoHeight
                pagination
                columns={columns}
                rows={datasetHistories?.data ?? []}
                rowCount={datasetHistories?.meta?.itemCount || 0}
                disableRowSelectionOnClick
                disableColumnSelector
                paginationMode="server"
                filterMode="server"
                sortingMode="server"
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                disableColumnFilter
                onPaginationModelChange={onPaginationChange}
                onFilterModelChange={onFilterChange}
                onSortModelChange={onSortChange}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[5, 10, 25, 50]}
                loading={loadingDatasetHistory}
                sx={{
                  '*': {
                    outline: 'none !important',
                  },
                  '.MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 600,
                  },
                  '.MuiCircularProgress-svg': {
                    color: 'var(--primary)',
                  },
                  '.MuiButtonBase-root': {
                    color: 'var(--black)',
                  },
                  '.MuiInputBase-root::after': {
                    borderColor: 'var(--black)',
                  },
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <ConfirmModal
        isOpen={confirmModal?.isOpen}
        title={confirmModal?.title}
        description={confirmModal?.description}
        onSubmit={confirmModal?.onSubmit}
        onClose={onCloseModals}
      />
    </div>
  );
});

export default SubscriptionPage;

import { forwardRef, memo, useRef, useState } from 'react';
import clsx from 'clsx';
import { Input as ReactstrapInput, InputProps as ReactstrapInputProps } from 'reactstrap';
import classes from './styles.module.scss';
import { UseFormRegisterReturn } from 'react-hook-form';

interface InputProps extends ReactstrapInputProps {
  className?: string;
  label?: string;
  subLabel?: string;
  inputRef?: UseFormRegisterReturn;
  errorMessage?: string;
  isOptional?: boolean;
  disabled?: boolean;
  isPercentage?: boolean;
  defaultValue?: string;
  [key: string]: any;
}

const InputCustom: React.FC<InputProps & { ref?: React.Ref<HTMLInputElement> }> = memo(
  forwardRef((props: InputProps, ref: any) => {
    const {
      className,
      label,
      subLabel,
      inputRef,
      errorMessage,
      type,
      isOptional,
      disabled,
      defaultValue,
      isPercentage,
      ...rest
    } = props;
    const isDisabled = !!disabled;
    const { ref: formRef, ...inputProps } = inputRef || { ref: null };

    const [isEditing, setIsEditing] = useState(false);

    // Tạo ref trực tiếp để kiểm soát DOM
    const inputElementRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);

    const toggleEdit = () => {
      if (!isDisabled) {
        setIsEditing(true);
        // Focus vào input ngay khi click
        setTimeout(() => {
          const inputElement = inputElementRef.current;
          if (inputElement) {
            inputElement.focus();
            inputElement.select(); // Chọn toàn bộ nội dung
          }
        }, 0);
      }
    };

    return (
      <div className={clsx(classes.container, className, classes.inputCustom)}>
        {label && (
          <label>
            {label} {isOptional && <span>(Optional)</span>}
          </label>
        )}

        {!isEditing ? (
          <div
            className={clsx(classes.lableValue, {
              [classes.clickable]: !isDisabled,
              [classes.lableValuePercentage]: isPercentage,
            })}
            onClick={toggleEdit}
          >
            {defaultValue
              ? (Math.floor(parseFloat(defaultValue) * 100) / 100).toFixed(2)
              : <div className={classes.valuePlaceholder}>{rest?.placeholder}</div>}
          </div>
        ) : (
          <ReactstrapInput
            {...inputProps}
            key={inputRef?.name}
            innerRef={(el) => {
              if (typeof formRef === 'function') formRef(el);
              inputElementRef.current = el; // Gắn ref trực tiếp vào input
            }}
            type={type ?? 'text'}
            disabled={isDisabled}
            {...rest}
            onBlur={() => setIsEditing(false)} // Khi mất focus thì ngừng chỉnh sửa
          />
        )}

        {subLabel && <p className={classes.subLabel}>{subLabel}</p>}
        {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
      </div>
    );
  })
);

export default InputCustom;

import {IChangeDemographicsParams } from 'interfaces/demographics';
import classes from './styles.module.scss';
import { memo, useCallback, useRef, useState } from 'react';
import { Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack } from '@mui/material';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { useDispatch } from 'react-redux';

import ToastService from 'services/toastService';
import Messages from 'configs/messages';
import { UploadIcon } from 'assets';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import ApiService from 'services/apiService';
import apiRoutes from 'configs/apiRoutes';

const FILE_FORMATS = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];

interface ModalUploadScenarioProps {
  isOpen: boolean;
  onClose: () => void;
  handleSetDataUpload: (data:IChangeDemographicsParams) => void;
}

const ModalUploadScenario = memo((props: ModalUploadScenarioProps) => {
  const { isOpen, onClose, handleSetDataUpload } = props;
  const dispatch = useDispatch();
  const [file, setFile] = useState<File>(null);

  const imageInputRef = useRef<HTMLInputElement>(null);

  const onSelectFile = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const excelFile: File = event?.target?.files?.[0];
    if (excelFile) {
      if (!FILE_FORMATS.includes(excelFile.type)) {
        ToastService.error(Messages.error.excelFile);
      } else {
        setFile(excelFile);
      }
    }
    imageInputRef.current.value = '';
  }, []);


  const onSubmit = () => {
    if (!file) {
      return ToastService.error(Messages.error.requiredFile);
    }

    const formData = new FormData();
    formData.append('file', file);

    dispatch(setIsLoadingReducer(true));
    ApiService.POST(apiRoutes.scenario.uploadData, formData)
      .then((res) => {
        const params: IChangeDemographicsParams = {
          country: res?.data?.data?.country,
          topic: res?.data?.data?.topic,
          subTable: res?.data?.data?.subTable,
          startYear: res?.data?.data?.startYear,
          endYear: res?.data?.data?.endYear,
          stepSize: res?.data?.data?.stepSize,
          baseYear: res?.data?.data?.baseYear,
          breakpoint: res?.data?.data?.breakpoint || [],
          changedVariable: res?.data?.data?.changedVariable || []
        };
        handleSetDataUpload(params)
        setFile(null);
        onClose();
        ToastService.success(Messages.success.uploaded);
      })
      .catch(() => ToastService.error(Messages.error.default))
      .finally(() => dispatch(setIsLoadingReducer(false)));
  }

  return (
    <Dialog
      PaperProps={{ style: { overflowY: 'visible' } }}
      maxWidth={'xl'}
      transitionDuration={0.3}
      open={isOpen}
      onClose={onClose}
      className={classes.container}
    >
      <DialogTitle className={classes.title}>Upload file scenario</DialogTitle>

      <DialogContent sx={{ overflowY: { md: 'visible' } }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
              <Card sx={{ borderRadius: '10px' }}>
                <p className={classes.title}>File</p>
              
                <CardContent className={classes.content}>
                  <div>
                    <div className={classes.uploadWrapper}>
                      <div className={classes.uploadBox} onClick={() => imageInputRef?.current?.click()}>
                        <input ref={imageInputRef} type="file" onChange={onSelectFile} accept={FILE_FORMATS.join(', ')} />
                        <UploadIcon />
                        <p>Upload file</p>
                      </div>
                    </div>

                    <p className={classes.fileAllowed}>{file?.name ?? 'Allowed .xlsx'}</p>
                  </div>
                </CardContent>
              </Card>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ mt: 10 }} className={classes.dialogAction}>
        <Stack direction={'row'} flexWrap={'wrap'} gap={2} sx={{ ml: 'auto' }}>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>

          <PrimaryButton form="form-download-table" onClick={() => {onSubmit()}}>
            Upload
          </PrimaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
});

export default ModalUploadScenario;

import { Fragment, memo, useEffect, useMemo, useRef, useState } from 'react';
import classes from './styles.module.scss';
import {
  IChangeDemographicsFormData,
  IChangeDemographicsParams,
  IChartData,
  IDataChange,
  IDemographicsData,
  IDemographicsView,
  IGetDemographicsFormData,
  IGetDemographicsParams,
} from 'interfaces/demographics';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import DataControl from './DataControl';
import ApiService from 'services/apiService';
import apiRoutes from 'configs/apiRoutes';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import ToastService from 'services/toastService';
import Messages from 'configs/messages';
import ModalsChartDataTable from './ModalsChartDataTable';
import { ESubTable } from 'configs/enums';
import { IReducer } from 'redux/reducers';
import { Alert, Stack } from '@mui/material';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import DemographicsTable from './DemographicsTable';
import { Link } from 'react-router-dom';
import { EUserType } from 'configs/enums';
import { appRoutes } from 'routers/appRoutes';
import { DownloadIcon, CalculateIcon } from 'assets';
import ModalDownloadDataTable from './ModalDownloadDataTable';
import { saveAs } from 'file-saver';
import { IConfirmModal } from 'interfaces/common';
import ConfirmModal from 'components/Modals/ConfirmModal';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import ModalSetNameSaveScenario from './ModalSetNameSaveScenario';
import ModalGrowthRate from './ModalGrowthRate';

interface HomePageProps { }

export interface DataFilter{
  topic?: (string | number)[];
  subTable?: (string | number)[];
  yearRange? : number[];
}
const HomePage: React.FC<HomePageProps> = memo((props: HomePageProps) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state: IReducer) => state?.user);
  const { dataControl } = useSelector((state: IReducer) => state.dataControl);

  const dataInfoRef = useRef<HTMLDivElement>(null);

  const [hiddenSubTableIds, setHiddenSubTableIds] = useState<number[]>(null);
  const [demographicsData, setDemographicsData] = useState<IDemographicsData>(null);
  const [variableChartOpened, setVariableChartOpened] = useState<{ topicId: number; variableId: number }[]>([]);
  const [isOpenModalDownload, setIsOpenModalDownload] = useState<boolean>(false);
  const [changedVariables, setChangedVariables] = useState<IDataChange[]>([]);
  const [isBindChangedVariable, setIsBindChangedVariable] = useState<boolean>(null);
  const [queriedDataControl, setQueriedDataControl] = useState<IGetDemographicsParams>(null);
  const [currentView, setCurrentView] = useState<IDemographicsView>(null);
  const [confirmModal, setConfirmModal] = useState<IConfirmModal>(null);
  const [dataInfoHeight, setDataInfoHeight] = useState<number>(0);
  const [isSubmitForm, setIsSubmitForm] = useState<number>(0);
  const [isSubmitFormSaveChange, setIsSubmitFormSaveChange] = useState<boolean>(false);
  const [isCountryChange, setIsCountryChange] = useState<boolean>(null);
  const changedVariableIds = useMemo(() => changedVariables?.map((variable) => variable.variableId), [changedVariables]);
  const [isOpenSaveScenario, setIsOpenSaveScenario] = useState<boolean>(false)
  const [isOpenCalculate, setIsOpenCalculate]= useState<boolean>(false);

  const [dataFilter, setDataFilter] = useState<DataFilter>();

  const queriedCountries = useMemo(() => {
    if (dataControl && queriedDataControl) {
      return dataControl?.regions
        ?.flatMap((region) => region.countries)
        ?.filter((country) => queriedDataControl?.country?.includes(country.id))
        ?.map((country) => country.name)
        ?.join(', ');
    }
    return null;

  }, [dataControl, queriedDataControl?.country]);

  const listCharts: IChartData[] = useMemo(() => {
    if (demographicsData && variableChartOpened?.length > 0) {
      return variableChartOpened?.reduce((acc, item) => {
        const baseCaseDataRow = demographicsData?.topic
          ?.find((topic) => topic?.id === item?.topicId)
          ?.subTables?.find((subTables) => subTables.id === ESubTable.BaseCase)
          ?.dataRow?.find((dataRow) => dataRow.variableId === item?.variableId);

        const revisedDataRow = demographicsData?.topic
          ?.find((topic) => topic?.id === item?.topicId)
          ?.subTables?.find((subTables) => subTables.id === ESubTable.Revised)
          ?.dataRow?.find((dataRow) => dataRow.variableId === item?.variableId);
        if (baseCaseDataRow && revisedDataRow) {
          acc.push({
            baseCaseData: baseCaseDataRow,
            revisedData: revisedDataRow,
            forecastYear: demographicsData?.forecastYear,
          });
        }
        return acc;
      }, []);
    } else {
      return [];
    }
  }, [demographicsData, variableChartOpened]);

  useEffect(() => {
    if (!dataInfoRef?.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      setDataInfoHeight(dataInfoRef?.current?.offsetHeight ?? 0);
    });

    resizeObserver.observe(dataInfoRef?.current);

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    const availableTopics = demographicsData?.topic?.map((topic) => topic?.id);
    const currentChart = variableChartOpened?.map((chart) => chart.topicId);
    if (demographicsData && variableChartOpened?.length > 0 && currentChart?.some((ids) => !availableTopics?.includes(ids))) {
      const filteredChart = variableChartOpened?.filter((variableChart) => availableTopics?.includes(variableChart?.topicId));
      setVariableChartOpened(filteredChart);
    }
  }, [demographicsData, variableChartOpened]);

  useEffect(() => {
    if (changedVariables.length > 0 && demographicsData && isBindChangedVariable === false) {
      const changedDemographics = { ...demographicsData };
      changedVariables?.forEach((changedVariable) => {
        const topicIndex = changedDemographics?.topic?.findIndex((topic) => topic?.id === changedVariable.topicId);
        if (topicIndex >= 0) {
          const revisedSubTableIndex = changedDemographics?.topic[topicIndex]?.subTables?.findIndex((subTable) => subTable.id === ESubTable.Revised);
          if (revisedSubTableIndex >= 0) {
            const variableIndex = changedDemographics?.topic[topicIndex]?.subTables[revisedSubTableIndex]?.dataRow?.findIndex(
              (variableRow) => variableRow.variableId === changedVariable.variableId
            );
            if (variableIndex >= 0) {
              changedDemographics.topic[topicIndex].subTables[revisedSubTableIndex].dataRow[variableIndex].data = {
                ...changedDemographics.topic[topicIndex].subTables.find((subTable) => subTable.id === ESubTable.Revised).dataRow[variableIndex].data,
                ...changedVariable.reversedDataChanged,
              };
            }
          }
        }
      });
      setDemographicsData(changedDemographics);
      setIsBindChangedVariable(true);
    }
  }, [changedVariables, demographicsData, isBindChangedVariable]);
  // useEffect(() => {
  //   setIsSubmitFormSaveChange(true)
  // },[currentView])

  const onQueryDemographics = async (data: IGetDemographicsFormData) => {
    const topicIds = data?.topic?.map((topic) => topic.value);
    const subTableIds = data?.subTable?.map((subTable) => subTable.value);
    const params: IGetDemographicsParams = {
      country: data?.country?.map((country) => country.value),
      topic: topicIds,
      subTable: subTableIds,
      startYear: dayjs(data?.startYear).year(),
      endYear: dayjs(data?.endYear).year(),
      stepSize: data?.stepSize,
      baseYear: dayjs(data?.baseYear).year(),
      breakpoint: data?.customBreakpoints === true ? data?.breakpoints?.map((breakpoint) => breakpoint.value) : [],
    };
    setQueriedDataControl(params);
    setConfirmModal(null);
    if (params?.subTable?.includes(ESubTable.Revised) && !params?.subTable?.includes(ESubTable.BaseCase)) {
      params?.subTable?.push(ESubTable.BaseCase);
      setHiddenSubTableIds([ESubTable.BaseCase]);
    } else {
      setHiddenSubTableIds(null);
    }
    dispatch(setIsLoadingReducer(true));
    await ApiService.GET(apiRoutes.interdem.default, { ...params })
      .then((res) => {
        setIsSubmitForm(cloneDeep(isSubmitForm) + 1);
        setDemographicsData(res?.data);
        setChangedVariables([]);
        setVariableChartOpened(variableChartOpened?.filter((item) => topicIds?.includes(item.topicId)) ?? []);
        setIsCountryChange(false)
        setDataFilter({
          topic: params?.topic ?? [],
          subTable: params?.subTable ?? [],
          yearRange: res?.data?.yearRange ?? [],
        })
      })
      .catch((err) => {
        console.log(err);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onAppendChart = (topicId: number, variableId: number) => {
    if (variableChartOpened?.some((item) => item.topicId === topicId && item.variableId === variableId)) return;
    setVariableChartOpened([
      ...variableChartOpened,
      {
        topicId,
        variableId,
      },
    ]);
  };

  const onReloadWithEquationValues = (topicId: number, variableId: number) => {
    const newChangedVariables = cloneDeep(changedVariables)?.filter(it => (it.topicId !== topicId && it.variableId !== variableId)) ?? []
    setChangedVariables(newChangedVariables);
    setIsSubmitFormSaveChange(true)
  }

  const onPopChart = (topicId: number, variableId: number) => {
    setVariableChartOpened(variableChartOpened?.filter((item) => item.topicId !== topicId || item.variableId !== variableId));
  };

  const onChangeDataTable = (data: IDataChange) => {
    const updateChangedVariables = [...changedVariables];
    const changedDataIndex = updateChangedVariables?.findIndex((changedVariables) => changedVariables?.variableId === data?.variableId);
    if (changedDataIndex >= 0) {
      updateChangedVariables[changedDataIndex] = data;
    } else {
      updateChangedVariables.push(data);
    }
    setChangedVariables(updateChangedVariables);
    setIsBindChangedVariable(false);
    setIsSubmitFormSaveChange(true);
  };

  const onSubmitChange = async () => {

    const tempCurrentView = cloneDeep(currentView);

    const data: IChangeDemographicsParams = {
      country: queriedDataControl?.country,
      topic: tempCurrentView?.topic,
      subTable: tempCurrentView?.subTable,
      startYear: tempCurrentView.startYear,
      endYear: tempCurrentView.endYear,
      stepSize: tempCurrentView?.stepSize,
      baseYear: tempCurrentView?.baseYear,
      breakpoint: tempCurrentView?.customBreakpoints === true ? tempCurrentView?.breakpoints?.map((breakpoint) => Number(breakpoint.value)) : [],
      customBreakpoints: !!tempCurrentView?.customBreakpoints,
      changedVariable: changedVariables,
    };
    if (tempCurrentView?.subTable?.includes(ESubTable.BaseCase)) {
      setHiddenSubTableIds(null);
    } else {
      data?.subTable?.push(ESubTable.BaseCase);
      setHiddenSubTableIds([ESubTable.BaseCase]);
    }
    dispatch(setIsLoadingReducer(true));
    await ApiService.POST(apiRoutes.interdem.change, data)
      .then((res) => {
        setIsSubmitFormSaveChange(false)
        setDemographicsData(res?.data);
        ToastService.success(Messages.success.runNewScenario);
        setDataFilter({
          topic: tempCurrentView?.topic ?? [],
          subTable: tempCurrentView?.subTable ?? [],
          yearRange: res?.data?.yearRange ?? [],
        })
      })
      .catch((err) => {
        console.log(err);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onChangeWidthDataUpload = async (data: IChangeDemographicsParams) => {
    const params: IGetDemographicsParams = {
      country: data?.country?.map((country) => country),
      topic: data?.topic,
      subTable: data?.subTable,
      startYear: dayjs(data?.startYear).year(),
      endYear: dayjs(data?.endYear).year(),
      stepSize: data?.stepSize,
      baseYear: dayjs(data?.baseYear).year()
    };
    setQueriedDataControl(params);
    if (data?.subTable?.includes(ESubTable.BaseCase)) {
      setHiddenSubTableIds(null);
    } else {
      data?.subTable?.push(ESubTable.BaseCase);
      setHiddenSubTableIds([ESubTable.BaseCase]);
    }
    setChangedVariables(data?.changedVariable);
    dispatch(setIsLoadingReducer(true));
    await ApiService.POST(apiRoutes.interdem.change, data)
      .then((res) => {
        setIsSubmitFormSaveChange(true)
        setDemographicsData(res?.data);
        ToastService.success(Messages.success.runNewScenario);
      })
      .catch((err) => {
        console.log(err);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  }

  const onSaveScenario = (name: string) => {
    saveScenario(name)
    setIsOpenSaveScenario(false)
  }

  const saveScenario = async (name: string) => {
    const tempCurrentView = cloneDeep(currentView);
    const data: IChangeDemographicsParams = {
      ...queriedDataControl,
      ...(tempCurrentView
        ? {
          ...Object.keys(tempCurrentView ?? {}).reduce((acc, key) => {
            if (tempCurrentView[key] && (!Array.isArray(tempCurrentView[key]) || tempCurrentView[key]?.length > 0)) {
              acc[key] = tempCurrentView[key];
              return acc;
            } else {
              return acc;
            }
          }, {}),
        }
        : {}),
      changedVariable: changedVariables,
    };
    ApiService.POST(apiRoutes.interdem.saveScenario, data, {
      responseType: 'blob',
    })
      .then((res) => {
        const fileName = `${name}`;
        saveAs(res, fileName);
      })
      .catch((e) => {
        console.log(e);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  }

  const onResetToBase = () => {
    const changedDemographics = { ...demographicsData };
    changedDemographics?.topic?.forEach((topic) => {
      const impactSubTableIndex = topic?.subTables?.findIndex((subTable) => subTable.id === ESubTable.Impact);
      const revisedSubTableIndex = topic?.subTables?.findIndex((subTable) => subTable.id === ESubTable.Revised);
      const baseCaseSubTableIndex = topic?.subTables?.findIndex((subTable) => subTable.id === ESubTable.BaseCase);
      if (revisedSubTableIndex >= 0 && baseCaseSubTableIndex >= 0) {
        topic?.subTables[revisedSubTableIndex]?.dataRow?.forEach((row, rowIndex) => {
          row.data = { ...topic?.subTables[baseCaseSubTableIndex]?.dataRow[rowIndex]?.data };
        });
      }
      if (impactSubTableIndex >= 0) {
        topic?.subTables[impactSubTableIndex]?.dataRow?.forEach((row) => {
          row.data = {};
        });
      }
    });

    setDemographicsData(changedDemographics);
    setIsBindChangedVariable(true);
    setChangedVariables([]);
  };

  const onViewChange = (newView: IDemographicsView) => {
    setCurrentView(newView);
  };

  const onOpenModalDownload = () => {
    setIsOpenModalDownload(true);
  };

  const onCloseModalDownload = () => {
    setIsOpenModalDownload(false);
  };

  const handleDownloadTable = async (data: IChangeDemographicsFormData) => {
    dispatch(setIsLoadingReducer(true));
    const params: IChangeDemographicsParams = {
      country: data?.country?.map((country) => country.value),
      topic: data?.topic?.map((topic) => topic.value),
      subTable: data?.subTable?.map((subTable) => subTable.value),
      startYear: dayjs(data?.startYear).year(),
      endYear: dayjs(data?.endYear).year(),
      stepSize: 1,
      baseYear: dayjs(data?.startYear).year(),
      breakpoint: data?.customBreakpoints === true ? data?.breakpoints?.map((breakpoint) => breakpoint.value) : [],
      changedVariable: data?.changedVariable ?? [],
    };
    ApiService.POST(apiRoutes.interdem.download, params, {
      responseType: 'blob',
    })
      .then((res) => {
        const cutoffCountriesName =
          queriedCountries.length > 50 ? `${queriedCountries.substring(0, queriedCountries.lastIndexOf(',', 50)).trim()}-etc` : queriedCountries;
        const fileName = `Interactive Demographics - ${cutoffCountriesName} - ${dayjs().format('YYYY-MM-DD')}`;
        saveAs(res, fileName);
        onCloseModalDownload();
      })
      .catch((e) => {
        console.log(e);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onCloseModal = () => {
    setConfirmModal(null);
  };

  const handleSetIsCountryChange = (isChange: boolean) => {
    setIsCountryChange(isChange);
  }

  const handleSubmitDataControl = (data: IGetDemographicsFormData) => {
    if (changedVariables?.length > 0 && isCountryChange) {
      setConfirmModal({
        isOpen: true,
        title: 'Get new demographics data',
        description:
          "Are you sure you want to reset everything to base? This action will restore all the changed variables, if you want to keep them and apply new data control, please click on the 'Run new scenario' button.",
        onSubmit: () => onQueryDemographics(data),
      });
    } else if (changedVariables?.length > 0) {
      onSubmitChange();
    } else {
      onQueryDemographics(data)
    }
  };

  const handleSubmitResetBase = () => {
    setConfirmModal({
      isOpen: true,
      title: 'Get new demographics data',
      description:
        "Are you sure you want to reset everything to base? This action will restore all the changed variables, if you want to keep them and apply new data control, please click on the 'Run new scenario' button.",
      onSubmit: () => {
        onResetToBase();
        setConfirmModal(null);
      },
    });
  }
  const onOpenGrowthRate = () => {
    setIsOpenCalculate(!isOpenCalculate)
  };
  return (
    <Fragment>
      {user?.type === EUserType.Customer && !user?.subscription ? (
        <div className={classes.subscriptionRequired}>
          You are currently on a free plan which provides access to New Zealand country only. If you want to access all the countries, please subscribe to
          a plan <Link to={appRoutes.private.pricing}>here</Link>.
        </div>
      ) : null}

      <DataControl
        isSubmitForm={isSubmitForm}
        setIsSubmitFormSaveChange={setIsSubmitFormSaveChange}
        onViewChange={onViewChange}
        onSubmit={handleSubmitDataControl}
        handleSetIsCountryChange={handleSetIsCountryChange}
        onChangeWidthDataUpload={onChangeWidthDataUpload}
        isHaveDatatabe={!!demographicsData?.topic}
      />

      <Alert severity="info" className={clsx(classes.dataInfo, { 'd-none': !queriedCountries })} ref={dataInfoRef}>
        {queriedCountries ? (
          <Fragment>
            <p className={classes.countryList}>Demographics of: {queriedCountries}.</p>
            {changedVariables?.length > 0 ? (
              <Stack sx={{ ml: 'auto' }} direction={'row'} flexWrap={'wrap'} alignItems={'center'} justifyContent={'flex-end'} columnGap={2} rowGap={1}>

                {isSubmitFormSaveChange && (
                  <>
                    <div className={classes.titleChangeNewScenario}>Changes have been made:</div>
                    <PrimaryButton type="button" onClick={onSubmitChange}>
                      Run new scenario
                    </PrimaryButton>

                  </>
                )}
                <SecondaryButton type="button" onClick={() => setIsOpenSaveScenario(true)}>
                  Save scenario
                </SecondaryButton>
                <SecondaryButton type="button" onClick={handleSubmitResetBase}>
                  Reset data to base case
                </SecondaryButton>
              </Stack>
            ) : (
              <Stack sx={{ ml: 'auto' }}>
                <strong style={{ color: 'var(--green)' }}>No changes yet.</strong>
              </Stack>
            )}
            <Stack sx={{ ml: 'auto' }} direction={'row'} flexWrap={'wrap'} alignItems={'center'} justifyContent={'flex-end'} columnGap={2} rowGap={1}>
              <div className={classes.groupCalculate}>
                <button className={classes.calculateBtn} type="button" onClick={onOpenGrowthRate} disabled={!demographicsData}>
                  <CalculateIcon />Growth Rate
                </button>
                <ModalGrowthRate 
                  isOpen={isOpenCalculate}
                  demographicsData={demographicsData}
                  dataFilter={dataFilter}
                  hiddenSubTableIds={hiddenSubTableIds}
                />
              </div>
              <PrimaryButton className={classes.downloadBtn} type="button" onClick={onOpenModalDownload} disabled={!demographicsData}>
                <DownloadIcon />Download table
              </PrimaryButton>
            </Stack>
          </Fragment>
        ) : null}
      </Alert>

      {demographicsData ? (
        <DemographicsTable
          demographicsData={demographicsData}
          hiddenSubTableIds={hiddenSubTableIds}
          changedVariableIds={changedVariableIds}
          onAppendChart={onAppendChart}
          onReloadWithEquationValues={onReloadWithEquationValues}
          dataInfoHeight={dataInfoHeight}
        />
      ) : null}

      <ModalsChartDataTable
        chartsData={listCharts}
        yearRangeViewStep={demographicsData?.yearRange}
        onSubmit={onChangeDataTable}
        yearRange={[currentView?.startYear ?? queriedDataControl?.startYear, currentView?.endYear ?? queriedDataControl?.endYear]}
        onPopChart={onPopChart}
      />

      {isOpenModalDownload ? (
        <ModalDownloadDataTable
          isOpen={isOpenModalDownload}
          data={{
            ...queriedDataControl,
            ...(currentView
              ? {
                ...Object.keys(currentView ?? {}).reduce((acc, key) => {
                  if (currentView[key] && (!Array.isArray(currentView[key]) || currentView[key]?.length > 0)) {
                    acc[key] = currentView[key];
                    return acc;
                  } else {
                    return acc;
                  }
                }, {}),
              }
              : {}),
            changedVariable: changedVariables,
          }}
          onClose={onCloseModalDownload}
          onSubmit={handleDownloadTable}
        />
      ) : null}

      <ConfirmModal
        isOpen={confirmModal?.isOpen}
        title={confirmModal?.title}
        description={confirmModal?.description}
        onSubmit={confirmModal?.onSubmit}
        onClose={onCloseModal}
      />
      <ModalSetNameSaveScenario
        isOpen={isOpenSaveScenario}
        onSubmit={onSaveScenario}
        onClose={() => { setIsOpenSaveScenario(false) }}
      />
    </Fragment>
  );
});

export default HomePage;

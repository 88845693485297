import { useEffect, useRef } from 'react';

interface Props {
  callback: () => void;
  timeout: number;
}

const useAutoLogout = ({ callback, timeout }: Props) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const resetTimer = () => {
      if (timerRef.current) clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        console.log('Logout');
        callback();
      }, timeout);
    };

    const events: (keyof WindowEventMap)[] = ['mousemove', 'keydown', 'click', 'scroll'];

    events.forEach((event) => window.addEventListener(event, resetTimer));

    resetTimer();

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
      events.forEach((event) => window.removeEventListener(event, resetTimer));
    };
  }, [callback, timeout]);

  return null;
};

export default useAutoLogout;
